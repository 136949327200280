@import '~bravely/bravely';

$z-index-top: 2147483647;
$modal-font-size: 14px;
$modal-color-copy: $colorWhitePure;
$modal-color-background: $colorBlueDarkest;
$modal-color-accent: #19253a;
$modal-color-button: $colorSeafoamCerulean;

.gdpr-banner {
  z-index: $z-index-top;
  background: $modal-color-background;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;

  box-shadow: 0 0 0 2px $modal-color-accent;

  .gdpr-banner-body {
    margin: auto;
    max-width: 900px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media ($mobile-max) {
      flex-direction: column;
    }
  }

  .gdpr-banner__copy {
    font-size: $modal-font-size;
    max-width: 500px;
    margin-right: 45px;

    > p {
      color: $modal-color-copy;
    }

    @media ($mobile-max) {
      margin-right: 0;
      margin-bottom: 22.5px;
    }
  }

  .gdpr-banner__learn-more {
    display: block;
    font-weight: bold;
    color: $modal-color-copy;
    margin-top: 5px;
    @media ($mobile-max) {
      margin: 0;
      display: inline-block;
    }
  }

  .gdpr-banner__button {
    @include action-button($colorWhitePure, $colorBlueDarkest);
    padding-left: 45px;
    padding-right: 45px;
  }

  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  &.gdpr-banner--closed {
    opacity: 0;
    transform: translateY(110%);
  }
}
